<template>
  <div class="getCoinSiteBox">
    <NavBar title="提取地址"></NavBar>
    <div class="contentBox flexAuto flexColumn">
      <Space :height="30"></Space>
      <ListView :get-list="getList" empty="暂无地址" pull-refresh ref="listView">
        <template v-slot="{ data }">
          <div class="listBox" v-for="(item, index) of data" :key="index">
            <van-image
              :width="parseInt($pxToPxRatio(20), 10)"
              :height="parseInt($pxToPxRatio(20), 10)"
              lazy-load
              fit="cover"
              :src="require('@/assets/images/personal/myAssets/d1.png')"
              class="leftIcon"
            />
            <div class="content" @click="onSelect(item)">
              <div class="title dfaic">
                {{ item.remark }}
                <van-image
                  v-if="item.isDefault === 2"
                  :width="parseInt($pxToPxRatio(114), 10)"
                  :height="parseInt($pxToPxRatio(34), 10)"
                  class="ml16"
                  lazy-load
                  fit="cover"
                  :src="require('@/assets/images/personal/myAssets/defaultSite.png')"
                />
              </div>
              <div class="intro">{{ item.address }}</div>
            </div>
            <van-image
              :width="parseInt($pxToPxRatio(30), 10)"
              :height="parseInt($pxToPxRatio(30), 10)"
              lazy-load
              fit="cover"
              :src="require('@/assets/images/personal/myAssets/d2.png')"
              @click="showDelete(item)"
            />
          </div>
        </template>
      </ListView>
    </div>
    <Space :height="150"></Space>
    <van-button type="primary" class="fixedBottom bg" block @click="addSiteShow = true">添加地址</van-button>
    <van-action-sheet v-model="addSiteShow" title=" ">
      <div class="contentBox">
        <TextBox color="textColor1" class="pd30">提取地址</TextBox>
        <Space :height="5"></Space>
        <van-field placeholder="输入或者长按粘贴地址" v-model="site"></van-field>
        <van-divider />
        <Space :height="30"></Space>
        <TextBox color="textColor1" class="pd30">备注</TextBox>
        <Space :height="5"></Space>
        <van-field placeholder="请输入10字符以内的备注名称" v-model="remark"></van-field>
        <van-divider />
        <Space :height="30"></Space>
        <TextBox color="textColor1" class="pd24">验证码</TextBox>
        <Space :height="5"></Space>
        <van-field type="number" placeholder="请输入短信验证码" v-model="code">
          <template #button>
            <SendCode url="/v1/addr/withdrawAddrCode" no-phone></SendCode>
          </template>
        </van-field>
        <van-divider />
        <Space :height="44"></Space>
        <div class="dfjcsbaic">
          <TextBox color="textColor1" class="pd30">是否设为默认地址</TextBox>
          <van-switch v-model="defaultSite" />
        </div>
        <Space :height="74"></Space>
        <van-button block type="primary" class="radius10" @click="submit" :loading="$store.state.submitLoading"
          >确定</van-button
        >
        <Space :height="33"></Space>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="deleteSiteShow" title=" ">
      <div class="contentBox">
        <Space :height="30"></Space>
        <TextBox color="textColor1" class="pd24">验证码</TextBox>
        <Space :height="5"></Space>
        <van-field type="number" placeholder="请输入短信验证码" v-model="deleteCode">
          <template #button>
            <SendCode url="/v1/addr/withdrawAddrDelCode" no-phone></SendCode>
          </template>
        </van-field>
        <van-divider />
        <Space :height="74"></Space>
        <van-button block type="primary" class="radius10" @click="deleteSite" :loading="$store.state.submitLoading"
          >确认删除</van-button
        >
        <Space :height="33"></Space>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {
      addSiteShow: false,
      site: '',
      remark: '',
      code: '',
      defaultSite: false,
      deleteSiteShow: false,
      deleteItem: null,
      deleteCode: '',
    };
  },
  // created () {},
  mounted() {
    this.$store.commit('setBodyColor');
  },
  methods: {
    onSelect(res) {
      this.$store.commit('myAssets/save', {
        defaultSite: res.address,
      });
      this.$router.back();
    },
    async getList(page) {
      let res = await this.$http('get', '/v1/addr/addrRecord', {
        coinId: this.$route.query.id,
        page: page,
        size: 8,
      });

      return res;
    },
    submit: _.debounce(function() {
      if (!this.site) {
        this.$toast('请输入提取地址');
        return;
      }
      if (!this.code) {
        this.$toast('请输入短信验证码');
        return;
      }
      this.$store.commit('setSubmitLoading', true);
      this.$http('post', '/v1/addr/addWithdrawAddr', {
        address: this.site,
        coinId: Number(this.$route.query.id),
        isDefault: this.defaultSite ? 2 : 1,
        remark: this.remark,
        code: this.code,
      })
        .then(res => {
          this.$toast('添加成功');
          this.addSiteShow = false;
          this.$refs.listView.reset();
        })
        .all(() => {
          this.$store.commit('setSubmitLoading', false);
        });
    }),
    showDelete(item) {
      this.deleteSiteShow = true;
      this.deleteItem = item;
    },
    deleteSite() {
      if (!this.deleteCode) {
        this.$toast('请输入短信验证码');
        return;
      }
      this.$http('post', '/v1/addr/delWithdrawAddr', {
        id: this.deleteItem.id,
        code: this.deleteCode,
      }).then(res => {
        this.$toast('删除成功');
        this.$refs.listView.reset();
        this.deleteSiteShow = false;
      });
    },
  },
  watch: {
    addSiteShow(res) {
      if (res) {
        this.site = '';
        this.remark = '';
        this.code = '';
        this.defaultSite = false;
      }
    },
    deleteSiteShow(res) {
      if (res) {
        this.code = '';
      }
    },
  },
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.listBox {
  background: @white;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 29px 30px 35px;
  display: flex;
  .leftIcon {
    margin-top: 10px;
  }
  .content {
    padding: 0 20px;
    flex: auto;
    .title {
      font-size: 30px;
      font-weight: 400;
      color: #2c395b;
    }
    .intro {
      font-size: 24px;
      font-weight: 400;
      color: #9ea2b5;
      margin-top: 25px;
    }
  }
}
</style>
